import React from 'react';
import { Modal, Menu, Dropdown } from 'antd';

// componnets
import DownloadAndroid from '../DownloadAndroid';
import DownloadApple from '../DownloadApple';

// utils
import {
  getPrivacyUrl,
  getTermsUrl,
  getCookieUrl,
  getAmlUrl,
  getConductUrl,
  aboutUsUrl,
} from '../../utils/url';
import { setLanguageCode, reverseLayout } from '../../utils/language';

// constants
import { SERVICE_EMAIL, LANGUAGE_DATAS } from '../../constants';

import { ICONS } from '../../assets';

import L from './languages';

import './style.scss';

const className = 'componentWebFooter';


class WebFooter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.L = L();
  }

  // private

  // render
  renderDownloadSection = () => {
    return <div />;

    return (
      <div className={`${className}-section-box`}>
        <div className={`${className}-section-title`}>
          {this.L.APP_DOWNLOAD}
        </div>
        <DownloadApple />
        <div className={`${className}-download-btn-margin`} />
        <DownloadAndroid />
      </div>
    );
  }

  renderTermsSection = () => {
    return (
      <div
        className={`${className}-section-box`}
        style={reverseLayout() ? { alignItems: 'flex-end' } : {}}
      >
        <div className={`${className}-section-title`}>
          {this.L.SECTION_TERMS}
        </div>
        {/* 用户协议 */}
        <div
          className={`${className}-section-item`}
          onClick={() => window.open(getTermsUrl())}
        >
          {this.L.TERMS}
        </div>
        {/* 隐私政策 */}
        <div
          className={`${className}-section-item`}
          onClick={() => window.open(getPrivacyUrl())}
        >
          {this.L.PRIVACY}
        </div>
        {/* Cookie政策 */}
        <div
          className={`${className}-section-item`}
          onClick={() => window.open(getCookieUrl())}
        >
          {this.L.COOKIE}
        </div>
        {/* 反洗钱政策 */}
        <div
          className={`${className}-section-item`}
          onClick={() => window.open(getAmlUrl())}
        >
          {this.L.AML}
        </div>
        {/* 行为准则 */}
        <div
          className={`${className}-section-item`}
          onClick={() => window.open(getConductUrl())}
        >
          {this.L.CONDUCT_CODE}
        </div>
      </div>
    );
  }
  
  renderSupportSection = () => {
    return (
      <div
        className={`${className}-section-box`}
        style={reverseLayout() ? { alignItems: 'flex-end' } : {}}
      >
        <div className={`${className}-section-title`}>
          {this.L.SUPPORT}
        </div>
        {/* 关于我们 */}
        <div
          className={`${className}-section-item`}
          onClick={() => window.open(aboutUsUrl())}
        >
          {this.L.ABOUT_US}
        </div>
        {/* 官方声明
        <div
          className={`${className}-section-item`}
          onClick={() => window.open(annoucementsUrl())}
        >
          {this.L.ANNOUNCEMENTS}
        </div>
        {/* FAQ
        <div
          className={`${className}-section-item`}
          onClick={() => window.open(FAQUrl())}
        >
          {this.L.FAQ}
        </div>
        {/* 充提币状态
        <div
          className={`${className}-section-item`}
          onClick={() => window.open(depositWithdrawStatus())}
        >
          {this.L.DEPOSIT_WITHDRAW_STATUS}
        </div>
        {/* 官方验证
        <div
          className={`${className}-section-item`}
          onClick={() => window.open(officialVerification())}
        >
          {this.L.OFFICIAL_VERIFICATION}
        </div> */}
      </div>
    );
  }

  renderServiceEmailSection = () => {
    return (
      <div
        className={`${className}-section-box`}
        style={reverseLayout() ? { alignItems: 'flex-end' } : {}}
      >
        <div className={`${className}-section-title`}>
          {this.L.SERVICE_EMAIL}
        </div>
        <div bclassName={`${className}-section-item`}>
          {SERVICE_EMAIL}
        </div>
      </div>
    );
  }
  
  renderLanguageSection = () => {
    const languageMenu = (
      <Menu>
        {
          LANGUAGE_DATAS.map((data, index) => 
            <Menu.Item key={`${index}`}>
              <div className={`${className}-link`} onClick={() => { setLanguageCode(data.code); window.location.reload() }}>
                {data.name}
              </div>
            </Menu.Item>
          )
        }
      </Menu>
    );

    return (
      <div
        className={`${className}-section-box`}
        style={reverseLayout() ? { alignItems: 'flex-end' } : {}}
      >
        <div className={`${className}-section-title`}>
          {this.L.LANGUAGES}
        </div>

        <Dropdown overlay={languageMenu}>
          <div className={`${className}-language-box`}>
            <div className={`${className}-language-title`}>
              {this.L.LANGUAGES}
            </div>
            <img
              className={`${className}-caret-icon`} 
              src={ICONS.CARET_DOWN}
            />
          </div>
        </Dropdown>
      </div>
    );
  }

  render() {
    return (
      <div className={`${className}`}>
        <div className={`${className}-inner`}>
          <div
            className={`${className}-box`}
            style={reverseLayout() ? { marginRight: 0, paddingRight: 0, flexDirection: 'row-reverse', textAlign: 'right' } : {}}
          >
            <div className={`${className}-left-box`}>
              <img className={`${className}-logo`} src={ICONS.LOGO} />
            </div>

            <div className={`${className}-right-box`}>
              <div className={`${className}-section-row`}>
                {/* APP下载 */}
                {this.renderDownloadSection()}
                {/* 条款声明 */}
                {this.renderTermsSection()}
                {/* 服务支持 */}
                {this.renderSupportSection()}
              </div>
              <div className={`${className}-section-margin`} />
              <div className={`${className}-section-row`}>
                {/* 服务邮箱 */}
                {this.renderServiceEmailSection()}
                {/* 语言 */}
                {this.renderLanguageSection()}
              </div>
            </div>
          </div>
        </div>
        <div className={`${className}-copy-right-box`}>
          <div className={`${className}-copy-right`}>
          YLUCH COINS Market Services Limited | © 2018-2024 YLUCH COINS Market
          </div>
        </div>
      </div>
    );
  }
}

export default WebFooter;
