/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MARKET: `시장`,
    TRADE: `거래`,
    SPOT: `현물 거래`,
    OPTIONS: `옵션 거래`,
    CONTRACT: `계약 거래`,
    ASSET: `자산`,
    COPY_TRADE: `복사 거래`,
    LOAN: `대출`,
    USER_CENTER: `개인 센터`,
    SIGNIN: `로그인`,
    SIGNUP: `등록하다`,
    SLOGAN: `YLUCH COINS`
};
